<script lang="ts" setup>
import { iconsMap } from './UIDropdown.iconsMapping'
import type { UIDropdownProps } from './UIDropdown.props'

const { titleWrapper = 'h2' } = defineProps<UIDropdownProps>()

const paddingContainerOptions = {
  none: '',
  s: 'px-4 lg:px-8',
  m: 'px-4 lg:px-12',
  l: 'px-4 lg:px-16',
}
</script>

<template>
  <HeadlessDisclosure
    v-slot="{ open, close }"
    as="div"
    :default-open="defaultOpen"
  >
    <HeadlessDisclosureButton
      :id="clickId"
      class="flex w-full items-center gap-1 py-6"
      :class="[
        iconLeft ? 'flex-row-reverse justify-end' : 'justify-between',
        paddingContainerOptions[containerPadding ?? 'none'],
        {
          'py-sm md:py-lg': isLegalArea,
          'py-sm': isHelpArea,
        },
      ]"
    >
      <div class="flex items-center gap-2">
        <component :is="iconsMap[icon]" v-if="icon" aria-hidden="true" />
        <component
          :is="titleWrapper"
          v-if="title"
          :class="[
            {
              capitalize: isCapitalized,
              'text-book-6 md:text-book-5': isLegalArea,
            },
            customTitleStyle ? customTitleStyle : 'text-book-5',
          ]"
        >
          {{ title }}
        </component>
      </div>
      <div class="flex items-center gap-2">
        <span v-if="rightText">{{ rightText }}</span>
        <div v-if="plusIcon">
          <DefaultIconsAdd v-if="!open" aria-hidden="true" />
          <DefaultIconsRemove v-if="open" aria-hidden="true" />
        </div>
        <DefaultIconsChevronDown
          v-else
          aria-hidden="true"
          class="size-5 transform transition-all duration-500"
          :class="{ 'rotate-180': open }"
        />
      </div>
    </HeadlessDisclosureButton>
    <HeadlessTransitionRoot
      :show="open"
      enter="transition-all duration-500 overflow-hidden"
      enter-from="max-h-0"
      enter-to="max-h-screen"
      leave="transition-all duration-500 overflow-hidden"
      leave-from="max-h-screen"
      leave-to="max-h-0"
    >
      <HeadlessDisclosurePanel
        class="mb-6 transition-all duration-300 ease-linear"
        :class="{
          'max-h-0 pb-0': close && isZipCode,
          'max-h-full pb-28': open && isZipCode,
          '!mb-0': isZipCode,
        }"
      >
        <div
          v-if="isZipCode"
          class="bg-primitives-grey-50 overflow-hidden transition-all duration-300 ease-linear"
          :class="{
            'absolute left-0 right-0 top-10 max-h-full w-full p-4 pb-2':
              open && isZipCode,
            'absolute left-0 right-0 top-10 max-h-0': close && isZipCode,
          }"
        >
          <slot />
        </div>
        <div v-else><slot /></div>
      </HeadlessDisclosurePanel>
    </HeadlessTransitionRoot>
  </HeadlessDisclosure>
</template>
