import IconsReturn from '~icons/icons/Return.svg'
import IconsSecurePayment from '~icons/icons/SecurePayments.svg'
import IconsWallet from '~icons/icons/Wallet.svg'
import IconsCustomerCare from '~icons/icons/CustomerCare.svg'

export type IconType = keyof typeof iconsMap

export const iconsMap = {
  return: IconsReturn,
  securePayment: IconsSecurePayment,
  wallet: IconsWallet,
  customerCare: IconsCustomerCare,
}
